<template>
  <div
    class="banner-wrapper"
    style="background-color: var(--v-header-bg)"
  >
    <v-container fluid>
      <!-- -----------------------------------------------
            Start Banner
        ----------------------------------------------- -->
      <v-row>
        <v-col
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xs="12"
          class="align-center mt-0"
        >
          <div class="form-content text-center">
            <h1 class="heading mt-2">
              Playground
            </h1>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xs="12"
        >
          <score-chart style="height: 500px; width: 100%" />
          <calibrate-chart style="height: 500px; width: 100%" />
          <heatmap-chart style="height: 800px; width: 100%" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import CalibrateChart from '../../components/chart/calibrate/chart.vue';

export default {
  name: 'PlaygroundView',
  components: {
    ScoreChart: () => import('@/components/chart/score/chart'),
    CalibrateChart: () => import('@/components/chart/calibrate/chart'),
    HeatmapChart: () => import('@/components/chart/heatmap/chart'),
  },
  data() {
    return {
      number: null,
    };
  },
};
</script>

<style scoped>
.side-text {
  margin-top: 10px;
  padding: 20px;
}
.banner-wrapper {
  padding-top: 50px;
}
@media (min-width: 960px) {
  .side-text {
    margin-top: 200px;
    padding: 20px;
  }
  .banner-wrapper {
    padding-top: 100px;
  }
}
</style>
